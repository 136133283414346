import { window } from "browser-monads"
import React, { useContext } from "react"
import { CourseCard as STRLCourseCard } from "skillstrainer-resource-library"
import { NavigationContext } from "../context/Navigation"
import { getWebappRouteActionURL, WEBAPP_ROUTE_ACTIONS } from "../utils/request"
const { partner } = require("../data.json")

export default function CourseCard({ data: courseData }) {
  const { setPath } = useContext(NavigationContext)

  return (
    <STRLCourseCard
      data={{
        ...courseData,
        onBookDemo: () => {
          if (courseData.isDemoAvailable)
            window.open(
              getWebappRouteActionURL(WEBAPP_ROUTE_ACTIONS.book_demo_course, {
                courseId: courseData.courseId,
              }),
              "_blank"
            )
        },
      }}
      goToCategoryPage={() => setPath(`/courses/${courseData.categoryId}`)}
      goToDetailPage={() => setPath(`/course-details/${courseData.courseId}`)}
      payNow={async () =>
        window.open(
          getWebappRouteActionURL(WEBAPP_ROUTE_ACTIONS.purchase_course, {
            courseId: courseData.courseId,
            partnerId: partner?.id,
          }),
          "_blank"
        )
      }
    />
  )
}
